import { ChangeEvent, FormEvent, FunctionComponent, useState } from 'react'
import { ReserveHandleCaptureWrapper } from '@components/undock/forms/reserve-handle-capture/style'
import { Input } from '@components/undock/forms/input'
import { PrimaryButton } from '@components/undock/buttons'
import { AllowedThemes, Theme, themesMap } from '@components/undock/modules'

interface ReserveHandleCapturePropsI {
  cta?: string
}

const ReserveHandleCapture: FunctionComponent<ReserveHandleCapturePropsI> = ({
  cta = `Reserve handle`,
}) => {
  const [handle, setHandle] = useState<string>('')

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setHandle(e.target.value)
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <ReserveHandleCaptureWrapper onSubmit={handleSubmit}>
      <Input
        type="text"
        onChange={handleChange}
        placeholder="isabella"
        style={{
          width: '100%',
        }}
        value={handle}
      />
      <PrimaryButton
        style={{
          marginLeft: '1rem',
          width: '100%',
        }}
      >
        {cta}
      </PrimaryButton>
    </ReserveHandleCaptureWrapper>
  )
}

export default ReserveHandleCapture
