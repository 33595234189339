import { FunctionComponent } from 'react'
import Link from 'next/link'

import { ButtonIcon, PrimaryLink } from '@components/undock/buttons'
import { Theme } from '@components/undock/modules'
import { useTheme } from '@emotion/react'

interface ChromeButtonPropsI {}
const ChromeButton: FunctionComponent<ChromeButtonPropsI> = ({}) => {
  const theme = useTheme() as Theme

  return (
    <PrimaryLink
      href="https://chrome.google.com/webstore/detail/undock-meetings/bmppbndbfpegajdcdngndbfpifpbeckd"
      target="_blank"
      theme={theme}
    >
      <ButtonIcon src="/img/logos/logo-chrome.svg" />
      <span>Add to Chrome – it's free</span>
    </PrimaryLink>
  )
}

export default ChromeButton
