import { FunctionComponent } from 'react'
import Head from 'next/head'

interface UnfurlPropsI {
  description?: string
  image?: string
  keywords?: string[]
  title?: string
}

const Unfurl: FunctionComponent<UnfurlPropsI> = ({
  children,
  description = 'See suggestions for meetings times. Undock is autocomplete for your calendar.',
  image = '/img/og-image-default.jpg',
  keywords = ['scheduling', 'chrome extension', 'calendar', 'calendly'],
  title = 'Make time – anywhere you type',
}) => (
  <Head>
    <title>
      {!title.startsWith('Undock') ? 'Undock | ' : ''}
      {title}
    </title>
    <meta
      property="og:title"
      content={`${!title.startsWith('Undock') ? 'Undock | ' : ''}${title}`}
    />
    <meta
      name="twitter:title"
      content={`${!title.startsWith('Undock') ? 'Undock | ' : ''}${title}`}
    />

    <meta name="description" content={description} />
    <meta property="og:description" content={description} />
    <meta name="twitter:description" content={description} />

    <meta property="og:image" content={image} />
    <meta name="twitter:image" content={image} />

    <meta property="og:site_name" content="Undock" />
    <meta name="twitter:site" content="@undockhq" />
    <meta property="og:url" content="https://undock.com/" />
    <meta property="og:type" content="website" />

    <meta name="twitter:card" content="summary_large_image" />

    <meta name="keywords" content={keywords.join(', ')} />

    {children}
  </Head>
)

export default Unfurl
