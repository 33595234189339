import axios from 'axios'
import { FunctionComponent, useEffect, useState } from 'react'
import LottieComponent, { Options } from 'react-lottie'

import useIsInView from '@components/undock/hooks/isInView'
import { LottieWrapper } from '@animations/lottie/style'

interface LottiePropsI {
  file: string
  options?: any
}

const Lottie: FunctionComponent<LottiePropsI> = ({
  file = null,
  options = {},
}) => {
  const [ref, entry] = useIsInView()

  const [animationData, setAnimationData] = useState<any>(null)
  const [isStopped, setIsStopped] = useState<boolean>(false)
  const [isPaused, setIsPaused] = useState<boolean>(false)

  const defaultOptions: Options = {
    animationData: animationData,
    autoplay: true,
    loop: true,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  }
  const lottieOptions: Options = {
    ...defaultOptions,
    ...options,
  }

  const fetchAnimationData = async () => {
    try {
      const { data } = await axios.get(file)
      setAnimationData(data)
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    if (!animationData) {
      fetchAnimationData()
    } else if (!lottieOptions.animationData) {
      lottieOptions.animationData = animationData
    }
  }, [animationData])

  useEffect(() => {
    if (entry && entry.intersectionRatio) {
      setIsPaused(entry.intersectionRatio <= 0.25)
    }
  }, [entry])

  return (
    <>
      {lottieOptions.animationData && (
        <LottieWrapper ref={ref}>
          <LottieComponent
            isPaused={isStopped}
            isStopped={isPaused}
            options={lottieOptions}
          />
        </LottieWrapper>
      )}
    </>
  )
}

export default Lottie
