import { AllowedThemes, Theme, themesMap } from '@components/undock/modules'
import styled from '@emotion/styled'
import { colorTextLight } from '@lib/colors'
import { lighten } from 'polished'

interface InputPropsI {
  theme?: Theme
}

export const Input = styled.input<InputPropsI>(
  {
    alignContent: 'center',
    background: 'white',
    border: `solid 1px ${lighten(0.5, colorTextLight)}`,
    borderRadius: '.5rem',
    display: 'flex',
    fontFamily: '"Inter", sans-serif',
    height: '3rem',
    outline: 'none',
    padding: '.25rem .5rem',
  },
  ({ theme = themesMap.light }) => ({
    '&:focus': {
      borderColor: theme.colors.primaryButtonBackground,
    },
  })
)

interface TextareaPropsI {
  theme?: Theme
}

export const Textarea = styled.textarea<TextareaPropsI>(
  {
    background: 'white',
    border: `solid 1px ${lighten(0.5, colorTextLight)}`,
    borderRadius: '.5rem',
    fontFamily: '"Inter", sans-serif',
    outline: 'none',
    padding: '.5rem',
    resize: 'none',
  },
  ({ theme = themesMap.light }) => ({
    '&:focus': {
      borderColor: theme.colors.primaryButtonBackground,
    },
  })
)
