import styled from '@emotion/styled'
import { AllowedLayouts } from '@components/undock/modules/sideBySide'
import { mediaQueries } from '@lib/mediaQueries'
import { colorBlack, colorTextLabel, gold } from '@lib/colors'
import { PrimaryLink } from '@components/undock/buttons'
import { FadeUpWrapper } from '@animations/fadeUp'
import { rgba } from 'polished'
import { Theme, themesMap } from '@components/undock/modules'
import { LottieWrapper } from '@animations/lottie/style'

export const CallToAction = styled(PrimaryLink)(
  mediaQueries({
    marginRight: [0, 0, 'auto'],
    marginTop: '2rem',
    width: ['100%', '100%', 'auto'],
  })
)

interface ContainerPropsI {
  layout?: AllowedLayouts
}
export const Container = styled.div<ContainerPropsI>(
  ({ layout = 'content-left' }) =>
    mediaQueries({
      alignContent: 'center',
      display: 'flex',
      flexDirection: layout === 'content-left' ? 'row-reverse' : 'row',
      flexWrap: ['wrap', 'wrap', 'nowrap'],
      justifyContent: 'space-between',
      margin: 'auto',
      padding: '4rem 0',
      width: ['100%', '100%', '90%', '80%', '70%'],
      [`${FadeUpWrapper}`]: {
        alignItems: 'space-around',
        display: 'flex',
        flexWrap: 'wrap',
        height: 'auto',
      },
    })
)

export const ContentWrapper = styled.div(
  mediaQueries({
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: ['100%', '100%', 'calc(50% - 3rem)'],
  })
)

export const CtasWrapper = styled.div({
  marginTop: '2rem',
  width: '100%',
})

export const Headline = styled.h2({
  fontSize: '54px',
  fontWeight: 500,
  lineHeight: '62px',
  width: '100%',
})

export const Icon = styled.img({
  display: 'inline-block',
  marginRight: '.5rem',
})

export const Image = styled.img({
  maxWidth: '100%',
})

export const ImageWrapper = styled.div(
  mediaQueries({
    alignContent: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: ['100%', '100%', 'calc(50% - 2rem)'],
    [`${LottieWrapper}`]: {
      transform: 'scale(1.25) translateX(2rem)',
    },
  })
)

export const Label = styled.div({
  alignItems: 'center',
  color: colorTextLabel,
  display: 'flex',
  fontSize: '14px',
  fontWeight: 'bold',
  lineHeight: '16px',
  letterSpacing: '1px',
  marginBottom: '2rem',
  textTransform: 'uppercase',
  width: '100%',
})

export const ListItemsWrapper = styled.div({
  width: '100%',
})

interface ListItemPropsI {
  theme?: Theme
}
export const ListItem = styled.div<ListItemPropsI>(
  {
    display: 'flex',
    fontSize: '.9375rem',
    fontWeight: 500,

    '&:before': {
      backgroundImage:
        'url(//images.ctfassets.net/69ygoegobx90/6qYwSMUrHWFo6IcPrzGAmH/ade2c5ab00ee5f6b1e9e168733ba0848/icon-list-item.svg)',
      content: '""',
      display: 'inline-block',
      height: '1.25rem',
      marginRight: '.5rem',
      width: '1.25rem',
    },
    '&:not(:first-of-type)': {
      marginTop: '1rem',
    },
  },
  ({ theme = themesMap.light }) => ({
    color: theme.colors.textColor,
  })
)

export const SubHeadline = styled.p({
  color: colorTextLabel,
  fontWeight: 400,
  letterSpacing: '-0.02em',
  lineHeight: '32px',
  width: '100%',
})

export const ValuePropsWrapper = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
})

export const ValuePropHeadline = styled.h3({
  fontFamily: '"Inter", sans-serif !important' as any,
  fontSize: '.9375rem',
  textTransform: `none !important` as any,
})

export const ValuePropImage = styled.img({
  maxHeight: '3rem',
})

export const ValuePropImageWrapper = styled.div({
  alignContent: 'center',
  border: `solid 2px ${rgba(gold, 0.2)}`,
  borderRadius: '2rem',
  display: 'flex',
  height: '3rem',
  justifyContent: 'center',
  margin: '0 0 1rem',
  padding: '.75rem',
  width: '3rem',
})

export const ValuePropSubHeadline = styled.p({
  fontSize: '.875rem',
})

export const ValueProp = styled.div(
  mediaQueries({
    width: [
      '100%',
      '100%',
      'calc(50% - 1rem)',
      'calc(50% - 1rem)',
      'calc(50% - 1rem)',
    ],
  })
)
