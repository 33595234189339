import { FunctionComponent } from 'react'

import Module, { AllowedThemes, themesMap } from '@components/undock/modules'
import {
  ButtonsWrapper,
  Container,
  ContentWrapper,
  ImageWrapper,
  NewsContainer,
  NewsHeadline,
  NewsItem,
  NewsItems,
  SubHeadline,
} from '@components/undock/modules/hero/style'

import Lottie from '@animations/lottie'
import SignupButton from '@components/undock/ctas/signup'
import { HeroModuleI } from '@lib/contentful/entries'

interface HeroModulePropsI extends HeroModuleI {
  themeName: AllowedThemes
}

const HeroModule: FunctionComponent<HeroModulePropsI> = ({
  id = 'NO_ID_PROVIDED',
  image = null,
  headline = null,
  news = null,
  subHeadline = null,
  themeName = 'light',
}) => (
  <Module
    id={id}
    style={{
      alignContent: 'center',
      flexWrap: 'wrap',
      padding: '12rem 2rem 6rem',
    }}
    theme={themesMap[themeName]}
  >
    <Container>
      <ContentWrapper>
        <h1>{headline}</h1>
        <SubHeadline>{subHeadline}</SubHeadline>

        <ButtonsWrapper>
          <SignupButton />
        </ButtonsWrapper>
      </ContentWrapper>

      <ImageWrapper>
        {image && !image.endsWith('.json') && (
          <img
            alt={headline}
            src={image}
            style={{
              display: 'block',
              margin: 'auto',
              maxWidth: '100%',
            }}
            title={headline}
          />
        )}
        {image && image.endsWith('.json') && (
          <Lottie
            file={image}
            options={{
              rendererSettings: {
                preserveAspectRatio: 'meet',
              },
            }}
          />
        )}
      </ImageWrapper>
    </Container>
    {news && (
      <NewsContainer>
        <NewsHeadline theme={themeName}>Featured in</NewsHeadline>
        <NewsItems>
          {news.map((newsItem) => (
            <NewsItem
              href={newsItem.url}
              key={newsItem.url}
              rel="noopener"
              target="_blank"
            >
              <img src={newsItem.logo} title={newsItem.title} />
            </NewsItem>
          ))}
        </NewsItems>
      </NewsContainer>
    )}
  </Module>
)

export default HeroModule
