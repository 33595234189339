import { FunctionComponent, useEffect, useState } from 'react'
import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import useIsInView from '@components/undock/hooks/isInView'

const animation = keyframes({
  from: {
    transform: `translateY(4rem)`,
  },
  to: {
    opacity: 1,
    transform: `translateY(0%)`,
  },
})

export const fadeUp = ({ easing = 'ease', speed = '.75s' }) => ({
  animation: `${animation} ${speed} ${easing}`,
  animationFillMode: 'both',
})

interface FadeUpWrapperPropsI {
  intersectionRatio: number
}
export const FadeUpWrapper = styled.div<FadeUpWrapperPropsI>(
  ({ intersectionRatio = 0 }) => ({
    height: '100%',
    opacity: 1 * intersectionRatio,
    transform: `translateY(calc(8rem * (1 - ${intersectionRatio})))`,
    width: '100%',
  })
)

interface FadeUpPropsI {
  threshold?: number
}
const FadeUp: FunctionComponent<FadeUpPropsI> = ({
  children,
  threshold = 0,
}) => {
  const [ref, entry] = useIsInView()
  const [intersectionRatio, setIntersectionRatio] = useState<number>(0)

  useEffect(() => {
    if (entry && entry.intersectionRatio) {
      const ratio =
        threshold > 0
          ? entry.intersectionRatio > threshold
            ? entry.intersectionRatio / (1 - threshold)
            : 0
          : entry.intersectionRatio
      setIntersectionRatio(ratio)
    }
  }, [entry])

  return (
    <FadeUpWrapper intersectionRatio={intersectionRatio} ref={ref}>
      {children}
    </FadeUpWrapper>
  )
}

export default FadeUp
