import { Dispatch, useEffect, useRef, useState } from 'react'

type UseIsInView = () => [Dispatch<any>, any]

const useIsInView: UseIsInView = () => {
  const [entry, updateEntry] = useState(null)
  const [node, setNode] = useState(null)

  const observer = useRef<IntersectionObserver>()

  // Update the state any time entries change
  const handleEntryUpdates = ([ entry ]) => {
    updateEntry(entry)
  }

  useEffect(() => {
    observer.current = new IntersectionObserver(handleEntryUpdates, {
      root: null,
      rootMargin: '0px',
      threshold: Array.from(Array(60).keys(), i => i / 60)
    })
  }, [])

  useEffect(
    () => {
      const { current: currentObserver } = observer
      currentObserver.disconnect()

      if (node) currentObserver.observe(node)

      return () => currentObserver.disconnect()
    },
    [node]
  )

  return [setNode, entry]
}

export default useIsInView