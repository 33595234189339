import { FunctionComponent, useEffect } from 'react'

import Module, { themesMap } from '@components/undock/modules'
import {
  Container,
  ContentWrapper,
  CtasWrapper,
  Headline,
  Image,
  ImageWrapper,
  Label,
  ListItem,
  ListItemsWrapper,
  SubHeadline,
  ValueProp,
  ValuePropHeadline,
  ValuePropImage,
  ValuePropImageWrapper,
  ValuePropSubHeadline,
  ValuePropsWrapper,
} from '@components/undock/modules/sideBySide/style'
import FadeUp from '@animations/fadeUp'
import { ctasMap } from '@components/undock/ctas'
import { SideBySideModuleI } from '@lib/contentful/entries'
import Lottie from '@animations/lottie'

export type AllowedLayouts = 'content-left' | 'content-right'

interface SideBySideModulePropsI extends SideBySideModuleI {
  subHeadline?: string
}
const SideBySideModule: FunctionComponent<SideBySideModulePropsI> = ({
  ctaText = 'Get started',
  ctaType = null,
  ctaUrl = '#',
  headline = null,
  id = 'NO_ID_PROVIDED',
  image = null,
  label = null,
  layout = 'content-left',
  listItems = null,
  subHeadline = null,
  themeName = 'light',
  valueProps = null,
}) => {
  let CTA = ctasMap[ctaType]

  useEffect(() => {
    if (ctaType && !CTA) {
      CTA = ctasMap[ctaType]
    }
  }, [ctaType, CTA])

  return (
    <Module
      id={id}
      style={{
        alignContent: 'center',
      }}
      theme={themesMap[themeName]}
    >
      <Container layout={layout}>
        {image && (
          <ImageWrapper>
            <FadeUp>
              {!image.endsWith('.json') && (
                <Image alt={headline} src={image} title={headline} />
              )}
              {image.endsWith('.json') && (
                <Lottie
                  file={image}
                  options={{
                    rendererSettings: {
                      preserveAspectRatio: 'meet',
                    },
                  }}
                />
              )}
            </FadeUp>
          </ImageWrapper>
        )}
        <ContentWrapper>
          <FadeUp threshold={0.125}>
            {label && <Label>{label}</Label>}
            {headline && <Headline>{headline}</Headline>}
            {subHeadline && <SubHeadline>{subHeadline}</SubHeadline>}
            {listItems && (
              <ListItemsWrapper>
                {listItems.map((text) => (
                  <ListItem key={text.toLowerCase().replace(/\s/, '-')}>
                    {text}
                  </ListItem>
                ))}
              </ListItemsWrapper>
            )}
            {valueProps && (
              <ValuePropsWrapper>
                {valueProps.map((valueProp) => (
                  <ValueProp
                    key={valueProp.headline.toLowerCase().replace(/\s+/, '_')}
                  >
                    {valueProp.image && (
                      <ValuePropImageWrapper>
                        <ValuePropImage src={valueProp.image} />
                      </ValuePropImageWrapper>
                    )}
                    {valueProp.headline && (
                      <ValuePropHeadline>
                        {valueProp.headline}
                      </ValuePropHeadline>
                    )}
                    {valueProp.subHeadline && (
                      <ValuePropSubHeadline>
                        {valueProp.subHeadline}
                      </ValuePropSubHeadline>
                    )}
                  </ValueProp>
                ))}
              </ValuePropsWrapper>
            )}
            {CTA && (
              <CtasWrapper>
                <CTA text={ctaText} themeName={themeName} url={ctaUrl} />
              </CtasWrapper>
            )}
          </FadeUp>
        </ContentWrapper>
      </Container>
    </Module>
  )
}

export default SideBySideModule
