import ChromeButton from '@components/undock/ctas/chrome-extension'
import CustomLink from '@components/undock/ctas/custom-link'
import SignupButton from '@components/undock/ctas/signup'
import MobileWaitlistCapture from '@components/undock/forms/mobile-waitlist-capture'
import ReserveHandleCapture from '@components/undock/forms/reserve-handle-capture'

export const ctasMap = {
  CHROME_EXTENSION: ChromeButton,
  CUSTOM_LINK: CustomLink,
  MOBILE_WAITLIST: MobileWaitlistCapture,
  RESERVE_HANDLE: ReserveHandleCapture,
  SIGN_UP: SignupButton,
}
