import Module, {
  AllowedTextAlign,
  AllowedThemes,
  themesMap,
} from '@components/undock/modules'
import {
  ColumnHeadline,
  ColumnImage,
  ColumnImageWrapper,
  ColumnSubHeadline,
  ColumnsWrapper,
  ContentWrapper,
  GridColumn,
  GridWrapper,
} from '@components/undock/modules/grid/style'
import { GridModuleI } from '@lib/contentful/entries'
import { FunctionComponent } from 'react'

interface GridModulePropsI extends GridModuleI {
  count?: number
  textAlign?: AllowedTextAlign
  themeName: AllowedThemes
  withGradient?: boolean
  withoutTopPadding?: boolean
}

const GridModule: FunctionComponent<GridModulePropsI> = ({
  backgroundImage = null,
  count = 4,
  headline = null,
  id = 'NO_ID_PROVIDED',
  label = null,
  subHeadline = null,
  textAlign = 'center',
  themeName = 'light',
  valueProps = [],
  withGradient = false,
  withoutTopPadding = false,
}) => {
  return (
    <Module
      backgroundImage={backgroundImage}
      id={id}
      theme={themesMap[themeName]}
      withGradient={withGradient}
      withoutTopPadding={withoutTopPadding}
    >
      <GridWrapper>
        {(label || headline || subHeadline) && (
          <ContentWrapper>
            {headline && <h2>{headline}</h2>}
            {subHeadline && <p>{subHeadline}</p>}
          </ContentWrapper>
        )}

        <ColumnsWrapper withoutTopPadding={withoutTopPadding}>
          {valueProps &&
            valueProps.map((column: any, idx: number) => {
              return (
                <GridColumn
                  align={textAlign}
                  columns={count}
                  key={`column-${idx}`}
                >
                  {column.image && (
                    <ColumnImageWrapper>
                      <ColumnImage src={column.image} />
                    </ColumnImageWrapper>
                  )}
                  {column.headline && (
                    <ColumnHeadline>{column.headline}</ColumnHeadline>
                  )}
                  {column.subHeadline && (
                    <ColumnSubHeadline>{column.subHeadline}</ColumnSubHeadline>
                  )}
                </GridColumn>
              )
            })}
        </ColumnsWrapper>
      </GridWrapper>
    </Module>
  )
}

export default GridModule
