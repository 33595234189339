import { AllowedTextAlign, Theme, themesMap } from '@components/undock/modules'
import styled from '@emotion/styled'
import { gold } from '@lib/colors'
import { mediaQueries } from '@lib/mediaQueries'
import { rgba } from 'polished'

export const ColumnHeadline = styled.h3({
  fontFamily: '"Inter", sans-serif !important' as any,
  fontSize: '1rem',
  textTransform: `none !important` as any,
})

export const ColumnImage = styled.img({
  maxHeight: '3rem',
})

export const ColumnImageWrapper = styled.div({
  alignContent: 'center',
  border: `solid 2px ${rgba(gold, 0.2)}`,
  borderRadius: '2rem',
  display: 'flex',
  height: '4rem',
  justifyContent: 'center',
  margin: '0 auto 2rem',
  padding: '1rem',
  width: '4rem',
})

interface ColumnSubHeadlinePropsI {
  theme?: Theme
}
export const ColumnSubHeadline = styled.p<ColumnSubHeadlinePropsI>(
  {},
  ({ theme = themesMap.light }) => ({
    color: theme.colors.textColor,
  })
)

interface ColumnsWrapperPropsI {
  withoutTopPadding?: boolean
}
export const ColumnsWrapper = styled.div<ColumnsWrapperPropsI>(
  mediaQueries({
    display: 'flex',
    flexWrap: ['wrap', 'wrap', 'wrap', 'nowrap', 'nowrap'],
    justifyContent: 'space-between',
  }),
  ({ withoutTopPadding = false }) =>
    mediaQueries({
      padding: withoutTopPadding
        ? ['0 0 2rem', '0 0 2rem', '0 0 3rem', '0 0 4rem', '0 0 6rem']
        : ['2rem 0', '2rem 0', '3rem 0', '4rem 0', '6rem 0'],
    })
)

export const ContentWrapper = styled.div({
  marginBottom: '4rem',
  textAlign: 'center',
  h2: {
    fontSize: '3.375rem',
    marginTop: '6rem',
  },
})

interface GridColumnPropsI {
  align: AllowedTextAlign
  columns: number
}
export const GridColumn = styled.div<GridColumnPropsI>(
  ({ align = 'center', columns = 4 }) =>
    mediaQueries({
      position: 'relative',
      textAlign: align,
      width: [
        '100%',
        '100%',
        '50%',
        `calc((100% / ${columns}) - 2rem)`,
        `calc((100% / ${columns}) - 2rem)`,
      ],
      zIndex: 1,
    })
)

export const GridWrapper = styled.div(
  mediaQueries({
    margin: 'auto',
    width: ['100%', '100%', '90%', '80%', '70%'],
  })
)
