import { FunctionComponent } from 'react'

import { PrimaryLink } from '@components/undock/buttons'
import { Theme } from '@components/undock/modules'
import { useTheme } from '@emotion/react'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { Icon } from '@components/undock/ctas/custom-link/style'

interface CustomLinkPropsI {
  text: string
  url: string
}
const CustomLink: FunctionComponent<CustomLinkPropsI> = ({ text, url }) => {
  const theme = useTheme() as Theme

  return (
    <PrimaryLink href={url} target="_blank" theme={theme}>
      <span>{text}</span>
      <Icon icon={faArrowRight} />
    </PrimaryLink>
  )
}

export default CustomLink
