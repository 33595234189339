import { ChangeEvent, FormEvent, FunctionComponent, useState } from 'react'
import { MobileWaitlistCaptureWrapper } from '@components/undock/forms/mobile-waitlist-capture/style'
import { Input } from '@components/undock/forms/input'
import { PrimaryButton } from '@components/undock/buttons'
import { AllowedThemes, Theme, themesMap } from '@components/undock/modules'

interface MobileWaitlistCapturePropsI {
  cta?: string
  theme: Theme
}

const MobileWaitlistCapture: FunctionComponent<MobileWaitlistCapturePropsI> = ({
  cta = `Send me the app`,
  theme = themesMap.light,
}) => {
  const [phone, setPhone] = useState<string>('')

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPhone(e.target.value)
  }

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <MobileWaitlistCaptureWrapper onSubmit={handleSubmit}>
      <Input
        type="text"
        onChange={handleChange}
        placeholder="Enter your phone number..."
        style={{
          width: '100%',
        }}
        value={phone}
      />
      <PrimaryButton
        style={{
          marginLeft: '1rem',
          width: '100%',
        }}
        theme={theme}
      >
        {cta}
      </PrimaryButton>
    </MobileWaitlistCaptureWrapper>
  )
}

export default MobileWaitlistCapture
