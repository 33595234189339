import styled from '@emotion/styled'
import { mediaQueries } from '@lib/mediaQueries'
import { fadeUp } from '@animations/fadeUp'
import { colorTextMedium } from '@lib/colors'
import { ModuleHeadline, ModuleSubheadline } from '@components/undock/modules'

export const ButtonsWrapper = styled.div({
  marginTop: '2rem',
})

export const Container = styled.div(
  mediaQueries({
    alignContent: 'center',
    display: 'flex',
    flexWrap: ['wrap', 'wrap', 'nowrap'],
    margin: 'auto',
    paddingTop: ['2rem', '2rem', 0],
    width: ['100%', '100%', '90%', '80%', '70%'],

    h1: {
      fontSize: '3.875rem',
      width: '100%',
    },
    p: {
      fontSize: '18px',
      fontWeight: 'normal',
      lineHeight: '32px',
      width: '100%',
    },
  })
)

export const ContentWrapper = styled.div(
  mediaQueries({
    ...fadeUp({
      speed: '0.75s',
    }),
    animationDelay: '.5s',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    margin: 'auto',
    opacity: 0,
    width: ['100%', '100%', '44%', '44%', '44%'],
  })
)

export const SubHeadline = styled(ModuleSubheadline)({
  fontSize: '18px',
  lineHeight: '32px',
})

export const ImageWrapper = styled.div(
  mediaQueries({
    ...fadeUp({
      speed: '0.75s',
    }),
    animationDelay: '.75s',
    marginLeft: [0, 0, '2rem'],
    marginTop: ['4rem', '4rem', 0],
    opacity: 0,
    width: ['100%', '100%', '50%', '50%', '50%'],
    '> div, img': {
      transform: 'scale(1.25) translateX(2rem)',
    },
  })
)

export const NewsContainer = styled.div(
  mediaQueries({
    ...fadeUp({
      speed: '0.75s',
    }),
    animationDelay: '1s',
    display: 'flex',
    flexWrap: ['wrap', 'wrap', 'wrap'],
    margin: 'auto',
    opacity: 0,
    width: ['100%', '100%', '90%', '80%', '70%'],
  })
)

export const NewsHeadline = styled.div({
  color: colorTextMedium,
  fontSize: '13px',
  fontWeight: 600,
  letterSpacing: '10px',
  lineHeight: '16px',
  margin: '8rem auto 0',
  textAlign: 'center',
  textTransform: 'uppercase',
})

export const NewsItem = styled.a(
  mediaQueries({
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: '0 1rem',
    width: ['calc(50% - 2rem)', 'calc(50% - 2rem)', '100%'],
    '&:nth-of-type(n+3)': {
      marginTop: ['2rem', '2rem', 0],
    },
  })
)

export const NewsItems = styled.div(
  mediaQueries({
    alignItems: 'center',
    display: 'flex',
    flexWrap: ['wrap', 'wrap', 'nowrap'],
    justifyContent: 'space-between',
    marginTop: '2rem',
    width: '100%',

    img: {
      filter:
        'brightness(0) invert(22%) sepia(5%) saturate(897%) hue-rotate(174deg) brightness(98%) contrast(89%)',
      height: '100%',
      maxHeight: '5rem',
      width: '100%',
    },
  })
)
